import loginAdminImg from "assets/img/admin_img.jpg";
import iconImg from "assets/img/logo_header.png";
import sideBarImg from "assets/img/sidebar-3.jpg";

var params = [];
params["baseUrl"] = "https://demo.planificadorchurrasco.aicor.es/";
params["apiUrl"] = "https://api.planificadorchurrasco.aicor.es/api/";
// params["urlBackend"] = "http://reservas_elchurrasco_api.test/";
params["loginAdminImg"] = loginAdminImg;
params["sideBarIcon"] = iconImg;
params["sideBarImg"] = sideBarImg;
params["sideBarColor"] = "#D1A72F";
params["firstColorRGB"] = "0, 176, 213";
params["firstColor"] = "#D1A72F";
params["firstColorHover"] = "#A9ECF9";
params["secondColor"] = "#E75169";
params["color_green"] = "#00C534";
params["color_red"] = "#FF0C56";
params["color_grey"] = "#BBBBBB";
params["proyectName"] = "Churrasco";
params["defaultTableLength"] = 10;
params["tableLengthArray"] = [10, 25, 50];
params["personalizacion"] = false;
params["dev_mode"] = true;
params["panel_url"] = "administrador";
params["web_url"] = "entidad";
params["GOOGLE_API_KEY"] = "AIzaSyAfsDJ2Ee7XCcfvUg1xwEmk-XY5bRfbOO4";

export default params;
